.kn-signin-container::before{
  content:"";
  position: absolute;
  top: 0; left: -25%;
  width: 100%; height: 100%;
  background-image: url(../assets/imgs/bg-signin.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  filter: grayscale(30%);// brightness(80%);
}
