.signup-main-container {
    display: inline-grid;
    grid-template-columns: 50% 50%;


  .left-container {
    //background: var(--ion-color-primary);
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    min-height: 100vh;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    min-height: 100vh;
    border: 0;

    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      justify-items: center;
      row-gap: 15px;

      ion-input {
        width: 380px;
      }
    }
  }


  .text {
    color: black;
    font-weight: bold;
  }

  @media (max-width: 1200px) {
    .left-container {
      display: none;
    }
  }
}
@media (max-width: 1200px) {


  .signup-main-container {
    grid-template-columns: 100%;
    //grid-template-row: auto auto;
    //grid-auto-flow: row;
  }
}

.kn-signup-container::before{
  content:"";
  position: absolute;
  top: 0; left: -25%;
  width: 100%; height: 100%;
  background-image: url(../assets/imgs/bg-signup.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  filter: grayscale(30%);// brightness(80%);
}
