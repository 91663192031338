.kn-info-text {
  color: #6b7280;
}

.kn-content-header {
  color: #0f172a;
}

.kn-content-abstract {
  color: #334155;
}

.kn-video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  overflow: hidden;
}

.kn-video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.signup-main-container {
  display: inline-grid;
  grid-template-columns: 50% 50%;
}
.signup-main-container .left-container {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  min-height: 100vh;
}
.signup-main-container .form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
  min-height: 100vh;
  border: 0;
}
.signup-main-container .form-container form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  row-gap: 15px;
}
.signup-main-container .form-container form ion-input {
  width: 380px;
}
.signup-main-container .text {
  color: black;
  font-weight: bold;
}
@media (max-width: 1200px) {
  .signup-main-container .left-container {
    display: none;
  }
}

@media (max-width: 1200px) {
  .signup-main-container {
    grid-template-columns: 100%;
  }
}
.kn-signup-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: -25%;
  width: 100%;
  height: 100%;
  background-image: url(../assets/imgs/bg-signup.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  filter: grayscale(30%);
}

.kn-back-translucent {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.8);
}

.kn-search-result__backcolor {
  background-color: white;
}
.kn-search-result__border-color {
  border-color: #f8fafc;
}

.kn-uok-detail-container {
  @apply h-full py-8 flex flex-col relative overflow-hidden lg:py-12;
}

.kn-uok-detail-container svg {
  display: inline;
}

.kn-uok-detail-sheet {
  @apply relative w-full px-6 py-12 bg-white
  shadow-xl shadow-slate-700/10 ring-1 ring-gray-900/5
  md:max-w-3xl md:mx-auto lg:max-w-4xl lg:pt-16 lg:pb-28;
}

.kn-uok-detail-prose {
  @apply mt-8 prose prose-slate mx-auto lg:prose-lg;
}

.kn-signin-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: -25%;
  width: 100%;
  height: 100%;
  background-image: url(../assets/imgs/bg-signin.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  filter: grayscale(30%);
}