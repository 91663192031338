
.kn-uok-detail-container {
  @apply h-full py-8 flex flex-col relative overflow-hidden lg:py-12
}

.kn-uok-detail-container svg {
  display: inline;
}

.kn-uok-detail-sheet {
  @apply relative w-full px-6 py-12 bg-white
  shadow-xl shadow-slate-700/10 ring-1 ring-gray-900/5
  md:max-w-3xl md:mx-auto lg:max-w-4xl lg:pt-16 lg:pb-28
}

.kn-uok-detail-prose {
  @apply mt-8 prose prose-slate mx-auto lg:prose-lg
}



