
.kn-back-translucent {
  background-color: #fff;
  background-color: rgba(255,255,255,0.8);
}

.kn-search-result{
  &__backcolor{
    background-color: white;//rgb(248 250 252);
  }

  &__border-color{
    border-color: #f8fafc;
  }

}
