
$kn-info-text-color: #6b7280;
$kn-content-header-color: #0f172a;
$kn-content-abstract-color: #334155;

.kn-info-text{
  color: $kn-info-text-color;
}

.kn-content-header{
  color: $kn-content-header-color;
}

.kn-content-abstract{
  color: $kn-content-abstract-color
}


.kn-video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  overflow: hidden;
}
.kn-video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

